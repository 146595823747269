import {
    SET_DISPLAYS,
	SET_DISPLAY_MESSAGE_TYPES,
	SET_DISPLAY_MESSAGES,
	SET_RUNNING_DISPLAY_LIST,
	CLEAR_RUNNING_DISPLAY_LIST,
	SET_RUNNING_MESSAGES_LIST,
	CLEAR_RUNNING_MESSAGES_LIST,
	SET_RUNNING_MESSAGE_TYPES_LIST,
	CLEAR_RUNNING_MESSAGE_TYPES_LIST,
	REMOVE_DISPLAY_MESSAGE
} from '../../redux/actionTypes'


const initial_state = {
	displays: {},
	messageTypes: [],
	messages: [],
	starts: {
		messages: false,
		displays: false,
		messageTypes: false
	}

}

const displaysReducer = (state = initial_state, action) => {

    switch (action.type) {

        case SET_DISPLAYS: {
			const displayObj = {}
			if (action?.displays?.data) {
				action.displays.data.forEach(display => {
					displayObj[display._id] = display
				})
			}

			return { ...state, displays: displayObj };
		}
		case SET_DISPLAY_MESSAGE_TYPES: {
			return { ...state, messageTypes: action.messageTypes.data };
		}
		case SET_DISPLAY_MESSAGES: {
			return { ...state, messages: action.messages.data };
		}
		case REMOVE_DISPLAY_MESSAGE: {
			let mess = state.messages.filter(message => action.payload._id !== message._id)
			return { ...state, messages: mess }
		}
		case SET_RUNNING_DISPLAY_LIST: {
			return { ...state, starts: { ...state.starts, displays: true } };
		}
		case CLEAR_RUNNING_DISPLAY_LIST: {
			return { ...state, starts: { ...state.starts, displays: false } };
		}
		case SET_RUNNING_MESSAGES_LIST: {
			return { ...state, starts: { ...state.starts, messages: true } };
		}
		case CLEAR_RUNNING_MESSAGES_LIST: {
			return { ...state, starts: { ...state.starts, messages: false } };
		}
		case SET_RUNNING_MESSAGE_TYPES_LIST: {
			return { ...state, starts: { ...state.starts, messageTypes: true } };
		}
		case CLEAR_RUNNING_MESSAGE_TYPES_LIST: {
			return { ...state, starts: { ...state.starts, messageTypes: false } };
		}
        default: return { ...state };
    }

}
export default displaysReducer
