import React from "react";
import Default from "../components/dashboard/dashboard.js";
import ListDisplays from '../components/displays/listDisplays.js'
import DisplayMonitor from '../components/displays/displayMonitor.js'
import DisplayMessages from '../components/displays/displayMessages.js'

export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Default /> },
	{ path: `${process.env.PUBLIC_URL}/displays`, Component: <ListDisplays /> },
	{ path: `${process.env.PUBLIC_URL}/displays/display/:displayId`, Component: <DisplayMonitor /> },
	{ path: `${process.env.PUBLIC_URL}/displays/messages`, Component: <DisplayMessages /> },
];
