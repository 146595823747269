import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { fetchDisplayList  } from '../../redux/displays/action.js'

const DisplayMonitor = () => {
	const dispatch = useDispatch();
	const displays = useSelector(content => content.Displays.displays);
	const displaysRunning = useSelector((state) => state.Displays.starts.displays);
	const { displayId } = useParams();
	const thisDisplay = displays[displayId]
	// console.log('THIS DISPLAY: ', thisDisplay)
	const messageCount = thisDisplay?.messages?.length
	// console.log('messageCount: ', messageCount)
	const rowCount = messageCount ? Math.round(messageCount / 2) : 1
	// console.log('rowCount: ', rowCount)

	useEffect(() => {
		if (!displaysRunning) {
			dispatch(fetchDisplayList())
		}
		document.querySelector(".sidebar-resizer").classList.add('d-none');
		document.querySelector(".page-sidebar").classList.add('d-none');
		document.querySelector(".page-body").classList.add('ml-0');


		return () => {
			document.querySelector(".sidebar-resizer").classList.remove('d-none');
			document.querySelector(".page-sidebar").classList.remove('d-none');
			document.querySelector(".page-body").classList.remove('ml-0');
		}
	}, []);

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-12 xl-100">
						<div className="card">
							{/*<div className="card-header" style={{padding: '10px'}}>
								<h5>Display: {thisDisplay?.label ?? 'No Display Selected'}</h5>
							</div>*/}
							{(!thisDisplay?.messages?.length) && (
								<div className="card-body text-center">
									<h5>There are currently no messages to display</h5>
								</div>
							)}
							{(thisDisplay?.messages?.length > 0) && (
								<div className="card-body" style={{padding:0}}>
									<div className={`display-img-grid-container display-img-grid-${messageCount} display-img-grid-container-rows-${rowCount}`}>
										{thisDisplay.messages.map(message => {
											return (<div className="display-img-grid-item">
												<div className="row">
													<div className="col">
														<div className="displayMonitor-pet-name-wrapper">
															<h1>{message?.data?.first_name} </h1>
														</div>
														<div className="displayMonitor-owner-wrapper">
															<table className="table table-borderless text-24">
																<tbody>
																	<tr>
																		<th>Breed:</th>
																		<td>{message?.data.breed.label}</td>
																		<th>Owner:</th>
																		<td>{message?.data.owner_first_name} {message?.data.owner_last_name}</td>
																	</tr>
																</tbody>
															</table>
														</div>
														{(message?.data?.image) && (
															<div className="display-img-image-wrapper">
																<img src={message?.data.image} alt={message?.data.first_name} />
															</div>
														)}
														{(!message?.data?.image) && (
															<div className="center no-image">
																<h2>No Image Available</h2>
															</div>
														)}
													</div>
												</div>
											</div>
										)})}

									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default DisplayMonitor;
