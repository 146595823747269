// TODO: need to update this later, but should toggle between local and dev/prod for now
const loc = document.location.origin === 'http://localhost:3000' ? 'http://localhost:3100' : document.location.origin

export const NODE_ENV = process.env.NODE_ENV;
export const BACKEND_URL = process.env.BACKEND_URL || loc
export const FRONTEND_ENV = process.env.FRONTEND_ENV;
export const DATA_PATH = `${BACKEND_URL}/data`;
export const PUB_PATH = `${BACKEND_URL}/pub`;

export const DATA_REFRESH_INTERVAL_SMALL = 10000 // 10 SECONDS
export const DATA_REFRESH_INTERVAL_MEDIUM = 1000 * 60 *5 // 5 MINUTES
export const DATA_REFRESH_INTERVAL_LONG = 1000 * 60 * 60 // 1 HOUR
