// DISPLAYS
export const SET_DISPLAYS = 'SET_DISPLAYS';
export const SET_DISPLAY_MESSAGE_TYPES = 'SET_DISPLAY_MESSAGE_TYPES';
export const WATCH_DISPLAY_LIST = 'WATCH_DISPLAY_LIST';
export const WATCH_DISPLAY_MESSAGE_TYPES = 'WATCH_DISPLAY_MESSAGE_TYPES';
export const SET_DISPLAY_MESSAGES = 'SET_DISPLAY_MESSAGES';
export const WATCH_DISPLAY_MESSAGES = 'WATCH_DISPLAY_MESSAGES';
export const SET_RUNNING_DISPLAY_LIST = 'SET_RUNNING_DISPLAY_LIST';
export const CLEAR_RUNNING_DISPLAY_LIST = 'CLEAR_RUNNING_DISPLAY_LIST';
export const SET_RUNNING_MESSAGES_LIST = 'SET_RUNNING_MESSAGES_LIST';
export const CLEAR_RUNNING_MESSAGES_LIST = 'CLEAR_RUNNING_MESSAGES_LIST';
export const SET_RUNNING_MESSAGE_TYPES_LIST = 'SET_RUNNING_MESSAGE_TYPES_LIST';
export const CLEAR_RUNNING_MESSAGE_TYPES_LIST = 'CLEAR_RUNNING_MESSAGE_TYPES_LIST';
export const REMOVE_DISPLAY_MESSAGE = 'REMOVE_DISPLAY_MESSAGE';

// BUSINESSES
export const SET_SELECTED_BUSINESS = 'SET_SELECTED_BUSINESS'

// AUTHENTICATION APP
export const LOGIN = "LOGIN";

// USER
export const SET_USER_DATA = "SET_USER_DATA";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const WATCH_USER_DATA = "WATCH_USER_DATA";

//CUSTOMIZER
export const ADD_COSTOMIZER ="ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS ="ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";

