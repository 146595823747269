import { displays } from '../../api/index.js'
import {
	SET_DISPLAYS,
	SET_DISPLAY_MESSAGE_TYPES,
	WATCH_DISPLAY_LIST,
	WATCH_DISPLAY_MESSAGE_TYPES,
	WATCH_DISPLAY_MESSAGES,
	CLEAR_RUNNING_MESSAGES_LIST,
	REMOVE_DISPLAY_MESSAGE
} from '../../redux/actionTypes'

export const fetchDisplayList = () => ({ type: WATCH_DISPLAY_LIST })
export const fetchDisplayMessageTypes = () => ({ type: WATCH_DISPLAY_MESSAGE_TYPES })
export const fetchDisplayMessagesList = () => ({ type: WATCH_DISPLAY_MESSAGES })
export const stopFetchDisplayMessagesList = () => ({ type: CLEAR_RUNNING_MESSAGES_LIST })

export const setDisplayList = (displayData) => {
	return ({
		type: SET_DISPLAYS,
		displays: displayData
	})
}

export const setDisplayMessageTypes = (displayData) => {
	return ({
		type: SET_DISPLAY_MESSAGE_TYPES,
		payload: displayData
	})
}

export const setDisplayMessages = (displayData) => {
	return ({
		type: SET_DISPLAY_MESSAGE_TYPES,
		messageTypes: displayData
	})
}

export const removeMessage = (messageId) => {
	return ({
		type: REMOVE_DISPLAY_MESSAGE,
		payload: { _id : messageId}
	})
}
